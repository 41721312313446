import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const OrderSummaryChart = () => {
  const [chartData] = useState({
    series: [
      {
        name: "Net Profit",
        data: [31, 40, 28, 51, 42, 109, 100, 90, 80, 70, 60, 50],
      },
      {
        name: "Revenue",
        data: [11, 32, 45, 32, 34, 52, 41, 60, 50, 45, 40, 38],
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      colors: ["#2f4cdd", "#b519ec"],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        shape: "circle",
      },
      legend: {
        show: true,
        fontSize: "12px",
        labels: {
          colors: "#000000",
        },
        position: "top",
        horizontalAlign: "left",
        markers: {
          width: 19,
          height: 19,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: undefined,
          radius: 4,
          offsetX: -5,
          offsetY: -5,
        },
      },
      stroke: {
        show: true,
        width: 4,
        colors: ["#2f4cdd", "#b519ec"],
      },
      grid: {
        borderColor: "#eee",
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "July",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        labels: {
          style: {
            colors: "#3e4954",
            fontSize: "13px",
            fontFamily: "Poppins",
            fontWeight: 100,
            cssClass: "apexcharts-xaxis-label",
          },
        },
        crosshairs: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#3e4954",
            fontSize: "13px",
            fontFamily: "Poppins",
            fontWeight: 100,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
    },
  });

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="area"
      height="350"
    />
  );
};

export default OrderSummaryChart;
