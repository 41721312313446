import React, { useEffect, useState } from "react";
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPostsApi, workoutManagementListApi } from "../components/APIs/Api";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";

export default function AddExercisesModal({
  title,
  show,
  onHide,
  buttonTitle,
  api,
  refresh,
  componentId,
  inputName,
  successMessage,
  selectedItemData,
  workoutExerciseId,
}) {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [loader, setLoader] = useState(false);
  const [exercisesOptions, setExercisesOptions] = useState([]);
  const [exercises, setExercises] = useState([
    { tempo: "", reps: "", restTime: "" },
  ]);

  useEffect(() => {
    if (selectedItemData) {
      setName(selectedItemData.exercise?._id || "");
      setExercises(
        selectedItemData.rounds || [{ tempo: "", reps: "", restTime: "" }]
      );
    }
  }, [selectedItemData]);

  const handleAddExercise = () => {
    setExercises([...exercises, { tempo: "", reps: "", restTime: "" }]);
  };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedExercises = exercises.map((exercise, i) =>
      i === index ? { ...exercise, [name]: value } : exercise
    );
    setExercises(updatedExercises);
  };

  const handleRemoveInput = (indexToRemove) => {
    setExercises(exercises.filter((input, index) => index !== indexToRemove));
  };

  async function getExercisesList() {
    setLoader(true);
    try {
      const response = await getPostsApi("active");
      setExercisesOptions(response?.data?.data?.exercises);
    } catch (e) {
      toast.error(e?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  async function handleAddFormSubmit(event) {
    event.preventDefault();

    if (name.trim().length < 1) {
      setNameError("Name is required");
      return;
    }
    setLoader(true);
    console.log(exercises);

    try {
      let postData;
      if (title === "Add New Exercise") {
        postData = {
          exerciseId: name,
          workoutId: componentId,
          rounds: exercises.map((exercise, index) => ({
            ...exercise,
            sets: index + 1,
          })),
        };
      } else if (title === "Edit Workout Exercise") {
        postData = {
          exerciseId: name,
          workoutId: componentId,
          rounds: exercises.map((exercise, index) => ({
            ...exercise,
            sets: index + 1,
          })),
          workoutExerciseId: workoutExerciseId,
        };
      }
      const res = await api(postData);
      setLoader(false);
      refresh();
      onHide(false);
      toast.success(successMessage);
    } catch (e) {
      setLoader(false);
      console.log(e?.response);
      toast.error(e?.response?.data?.message);
    }
  }

  useEffect(() => {
    getExercisesList();
  }, []);

  return (
    <Modal className="add-exercises-modal" show={show} onHide={onHide} centered>
      <div className="d-flex justify-content-between align-items-center p-3">
        <h3 className="text-center">{title}</h3>
        <button type="button" className="close" onClick={() => onHide()}>
          <span>×</span>
        </button>
      </div>

      <form onSubmit={handleAddFormSubmit}>
        <div className="form-group p-3">
          <label className="text-black font-w500">{inputName}</label>
          <div className="contact-name">
            <select
              className="form-control"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setNameError("");
              }}
            >
              <option value="">Select...</option>
              {exercisesOptions.map((option, index) => (
                <option key={index} value={option._id}>
                  {option.name}
                </option>
              ))}
            </select>
            <span className="text-danger">{nameError}</span>
          </div>
          <div
            className="mt-4 "
            style={{ maxHeight: "300px", overflow: "scroll" }}
          >
            <h4 className="m-0">Pack</h4>
            {exercises.map((exercise, index) => (
              <div
                key={index}
                className="exercises-form-details position-relative"
              >
                <div style={{ flexGrow: "1" }}>
                  <div>
                    <label>Set </label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={index + 1}
                    />
                  </div>
                  <div>
                    <label>Tempo</label>
                    <input
                      type="number"
                      className="form-control"
                      name="tempo"
                      value={exercise.tempo}
                      onChange={(e) => handleInputChange(index, e)}
                    />
                  </div>
                </div>
                <div style={{ flexGrow: "1" }}>
                  <div>
                    <label>Reps</label>
                    <input
                      type="number"
                      className="form-control"
                      name="reps"
                      value={exercise.reps}
                      onChange={(e) => handleInputChange(index, e)}
                    />
                    {/* {index !== 0 && (
                      <div>
                        <button
                          style={{ top: "10px" }}
                          className="remove-btn"
                          onClick={() => handleRemoveInput(index)}
                        >
                          {" "}
                          X
                        </button>
                      </div>
                    )} */}
                  </div>
                  <div>
                    <label>Rest Time</label>
                    <input
                      type="number"
                      className="form-control"
                      name="restTime"
                      value={exercise.restTime}
                      onChange={(e) => handleInputChange(index, e)}
                    />
                    {index !== 0 && (
                      <div>
                        <button
                          style={{ top: "10px" }}
                          className="remove-btn"
                          onClick={() => handleRemoveInput(index)}
                        >
                          {" "}
                          X
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-sm pr-0 add-input-button"
                onClick={handleAddExercise}
              >
                <FontAwesomeIcon icon={faCirclePlus} />
              </button>
            </div>
          </div>
        </div>

        <div className="p-3 d-flex justify-content-end">
          <button className="btn btn-sm btn-primary">
            {loader ? <Spinner animation="border" size="sm" /> : buttonTitle}
          </button>
        </div>
      </form>
    </Modal>
  );
}
